<template>
    <v-container no-gutters fluid class="px-5">
        <v-row class="white rounded-lg pt-5">
            <v-col v-if="!$vuetify.breakpoint.mobile" cols="3">
                <v-img v-if="$store.getters.getTeam" contain width="200px" :src="`${assetsUrl}/${$store.getters.getTeam.logo}`" />
                <div v-else>
                    <v-icon class="orange--text" style="font-size: 70px;" x-large> mdi-account-group </v-icon>
                </div>
                <v-img v-if="$store.getters.getPlayer" contain width="200px" class="mt-5 rounded-circle" :src="`${assetsUrl}/${$store.getters.getPlayer.image}`" />
                <div v-else>
                    <v-icon class="orange--text mt-16" style="font-size: 70px;"> mdi-account </v-icon>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mobile" cols="6">
                <v-img v-if="$store.getters.getTeam" contain width="200px" :src="`${assetsUrl}/${$store.getters.getTeam.logo}`" />
                <div v-else>
                    <v-icon class="orange--text" style="font-size: 70px;" x-large> mdi-account-group </v-icon>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mobile" cols="6">
                <v-img v-if="$store.getters.getPlayer" contain width="200px" class="rounded-circle" :src="`${assetsUrl}/${$store.getters.getPlayer.image}`" />
                <div v-else>
                    <v-icon class="orange--text" style="font-size: 70px;"> mdi-account </v-icon>
                </div>
            </v-col>
            <v-divider
                v-if="!$vuetify.breakpoint.mobile"
                vertical
            />
            <v-col cols="12" md="9" align="center">
                <div class="text-left orange--text" style="font-size: 30px;"> Επιλογή ομάδας</div>
                <v-autocomplete
                    v-model="team"
                    label="Διάλεξε Ομάδα"
                    color="orange"
                    item-color="orange"
                    item-text="name"
                    :items="teams"
                    class="font-weight-bold secondary-font"
                    return-object
                    clearable
                    @change="pickTeam"
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            color="black"
                            outlined
                            mandatory
                            class="text-subtitle-2 secondary-font font-weight-bold"
                            @click="data.select"
                            @click:close="remove(data.item)"
                        >
                            <v-avatar class="mr-2">
                                <v-img v-if="data.item.logo" :src="`${assetsUrl}/${data.item.logo}`" />
                                <v-icon v-else class="orange--text" large> mdi-account-group </v-icon>
                            </v-avatar>
                            {{ data.item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item" />
                        </template>
                        <template v-else>
                            <v-list-item-avatar>
                                <v-img v-if="data.item.logo" :src="`${assetsUrl}/${data.item.logo}`" />
                                <v-icon v-else class="orange--text" large> mdi-account-group </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="text-left secondary-font">
                                <v-list-item-title v-html="data.item.name" />
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
                <div class="orange--text mt-3 text-left" style="font-size: 30px;"> Επιλογή παίχτη</div>
                <v-autocomplete
                    v-model="player"
                    label="Διάλεξε Παίχτη"
                    color="orange"
                    item-color="orange"
                    :item-text="item => `${item.name} ${item.surname}`"
                    :items="players.filter(element => element.active)"
                    return-object
                    class="font-weight-bold secondary-font"
                    clearable
                    @change="pickPlayer"
                    @click:clear="fetchTeam(team.id)"
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            color="black"
                            outlined
                            mandatory
                            class="secondary-font text-subtitle-2 font-weight-bold"
                            @click="data.select"
                            @click:close="remove(data.item)"
                        >
                            <v-avatar v-if="data.item.image" class="mr-2">
                                <v-img :src="`${assetsUrl}/${data.item.image}`" />
                            </v-avatar>
                            <v-icon v-else color="black">
                                mdi-account
                            </v-icon>
                            {{ `#${data.item.jersey} ${data.item.name} ${data.item.surname}` }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item" />
                        </template>
                        <template v-else>
                            <v-list-item-avatar>
                                <img v-if="data.item.image" :src="`${assetsUrl}/${data.item.image}`" @error="data.item.image = null">
                                <v-icon v-else color="black">
                                    mdi-account
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="text-left secondary-font">
                                <v-list-item-title>
                                    <span style="font-family: Arial, sans-serif !important;">#</span>
                                    <span>{{ `${data.item.jersey} ${data.item.name} ${data.item.surname}` }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="6" align="end">
                <v-btn v-if="team" class="custom-orange white--text rounded-lg secondary-font" style="text-transform: capitalize !important;" @click="$router.push(`/Team/${team.id}`)">
                    {{ team.name }}
                </v-btn>
            </v-col>
            <v-col cols="6" align="start">
                <v-btn v-if="team" outlined class="rounded-lg secondary-font" color="red" style="text-transform: capitalize;" @click="clearChoices()">
                    Καθαρισμός
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import teamService from '@/services/team'
    export default {
        data() {
            return {
                players: [],
                teams: [],
                team: null,
                player: null,
                page: 1,
                size: 1000,
                totalPages: 1,
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API
            }
        },
        created() {
            if (this.$store.getters.getTeam) {
                this.fetchTeam(this.$store.getters.getTeam.id)
            }
            this.player = this.$store.getters.getPlayer
            this.fetchAllTeams()
        },
        methods: {
            pickTeam(team) {
                this.players = team?.players
                this.pickPlayer(null)
                this.player = null
                this.$store.dispatch('setTeam', team)
            },
            pickPlayer(player) {
                this.$store.dispatch('setPlayer', player)
            },
            clearChoices() {
                this.team = null
                this.player = null
                this.pickTeam(null)
                this.pickPlayer(null)
            },
            getTeams() {

            },
            fetchTeam(id) {
                teamService.getTeam(id).then(resp => {
                    this.team = resp.data
                    this.players = this.team.players.filter(player => player.active)
                    this.$store.dispatch('setTeam', this.team)
                })
            },
            fetchAllTeams() {
                teamService.getTeams(this.page, this.size).then(resp => {
                    this.teams = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            }
        }
    }
</script>

<style scoped>
.v-chip::before {
    background-color: white !important;
}

.selected-chip {
    color: orange;
}
</style>
